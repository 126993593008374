import React, { useEffect, useState } from "react";
import { updateAuth } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utils/contract";
import { API } from "../../Utils/API";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Abi_Main,
  Abi_Token,
  Abi_USDT,
  ContractAddress_Main,
  ContractAddress_Token,
  ContractAddress_USDT,
} from "../../Utils/ContractAddress";
import Sidebar from "../DashboardMain/Sidebar";

const ConvertToRagFund = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);

  let [Amount, setAmount] = useState("");

  let [spiner, setspiner] = useState(false);
  const [Wallet_Address, setWallet_Address] = useState("");
  const [loading, setloading] = useState(false);
  const [TopupStatus, setTopupStatus] = useState("0");
  let [USDT, setUSDT] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  const [LiveRate, setLiveRate] = useState("");

  const [Token_rate, setToken_rate] = useState(0);

  const [token, setToken] = useState(0);
  const [Fees, setFees] = useState(0);
  const [gameFees, setgameFees] = useState(0);
  const [gameFeeslive, setgameFeeslive] = useState(0);

  const [NetPayable, setNetPayable] = useState(0);
  const [NetPayableToken, setNetPayableToken] = useState(0);
  const [NetPayableUSDT, setNetPayableUSDT] = useState(0);

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("responce", responce);
      responce = responce.data.data[0][0];
      // console.log("getUserDetails", responce);

      setWallet_Address(responce.btcaddress);
      setTopupStatus(responce.top_up);
      setUSDT(responce.Net_Balance);
    } catch (e) {
      //  console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const GetWithdrawalAmountData = async (val) => {
    try {
      const amount = DOMPurify.sanitize(
        val.replace(/[^0-9]/gi, "").substring(0, 6)
      );

      let responce = await API?.post(
        `GetWithdrawalAmountData`,
        {
          uid: user,
          amount: amount,
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("responce", responce);
      responce = responce.data.data.records[0];
      console.log("GetWithdrawalAmountData", responce);

      if (Token_rate > 0) {
        setAmount(amount);
        setgameFees(amount);
        setNetPayableToken((parseFloat(amount) * Token_rate).toFixed(2));
        setFees(0);
        setNetPayableUSDT(0);
        setNetPayable(0);
      }
    } catch (e) {
      //  console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const getPriceForLiverate = async () => {
    let usdvalue = 100;
    let Token_rate = 0;
    let BUSD_rate = 0;
    let contract = await new window.web3.eth.Contract(
      Abi_Main,
      ContractAddress_Main
    );

    let data = await contract.methods.getPrice(usdvalue.toString()).call();
    //console.log(data);
    Token_rate = parseFloat(data[0]) / 1000000000;
    BUSD_rate = parseFloat(data[1]) / 1000000000000000000;

    let Token1toUSD = parseFloat(Token_rate) / parseFloat(30.0);
    // let GameFees = parseFloat(Token_rate) / parseFloat(2.5);
    let Liverate = "1 USDT = " + Token1toUSD.toFixed(6).toString() + " Coin";
    //console.log("Liverate", Liverate);

    setToken_rate(Token1toUSD);
    // console.log(Liverate);
    setLiveRate(Liverate);
    setgameFeeslive(Token1toUSD.toFixed(6).toString());
  };

  const WalletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc === "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const CalculateValue = async (val) => {
    const Selvalues = DOMPurify.sanitize(
      val.replace(/[^0-9]/gi, "").substring(0, 6)
    );
    // calculateToken(Selvalues);
    setAmount(Selvalues);
    setFees((Selvalues * 0.2).toFixed(4));
    setgameFees((Selvalues * 0.15).toFixed(4));
    setNetPayable(
      (Selvalues - Selvalues * 0.1).toFixed(4) - (Selvalues * 0.1).toFixed(4)
    );

    setNetPayableUSDT(
      ((Selvalues - Selvalues * 0.1 - Selvalues * 0.1) * 0.7).toFixed(4)
    );
    setNetPayableToken(
      (
        (Selvalues - Selvalues * 0.1 - Selvalues * 0.1) *
        0.3 *
        Token_rate
      ).toFixed(4)
    );
  };

  const InsertLiveRateByContract_API = async () => {
    try {
      let response = await API?.post(
        `InsertLiveRateByContract`,
        {
          uid: user,
          amount: gameFeeslive,
          remark: `Withdrawl-${user}`,
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("resp", response);
    } catch (e) {
      if (
        e.response?.data?.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };
  useEffect(() => {
    if (gameFeeslive > 0) {
      const callInsertLiveRate = async () => {
        await InsertLiveRateByContract_API();
      };

      callInsertLiveRate();
    }
  }, [gameFeeslive]);

  // const calculateToken = async (value) => {
  //   try {
  //     let responce = await API?.get(
  //       `getTokenValue?usdvalue=${value || 0}&type=Buy`,
  //       {
  //         headers: {
  //           Authorization: `${jwt}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     console.log("resp", responce?.data?.data[0][0]?.Token);
  //     setToken(responce?.data?.data[0][0]?.Token);
  //   } catch (e) {
  //     if (
  //       e.response.data.showableMessage ===
  //       "Please login to access this resource"
  //     ) {
  //       dispatch(
  //         updateAuth({
  //           isAuth: false,
  //           userId: null,
  //           jwtToken: null,
  //           ipAddress: null,
  //         })
  //       );
  //       navigate("/");
  //     }
  //   }
  // };

  const ConvertToRagFundUser = async (tstatus) => {
    try {
      if (user === undefined || user === "" || user === null) {
        navigate("/");
      }
      let acc = await loadWeb3();
      console.log(Wallet_Address.toUpperCase(), acc.toUpperCase());
      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
      } else if (acc === "Wrong Network") {
        console.log("Wrong Network Please Connect Polygon Scan Network");
      } else {
        if (Wallet_Address.toUpperCase() === acc.toUpperCase()) {
          if (Amount !== 0) {
            if (parseFloat(USDT) < parseFloat(Amount)) {
              toast.error("Insufficient USDT Balance");
              setspiner(false);
              return;
            } else {
              setspiner(true);
            }

            if (parseFloat(Amount) < parseFloat(10)) {
              toast.error("You can convert minimum 10 USDT");
              setspiner(false);
              return;
            } else {
              setspiner(true);
            }

            let res = await API.post(
              "/ConvertToFund",
              {
                uid: user,
                usd: Amount,
                token: NetPayableToken,
                bnb: NetPayableUSDT,
              },
              {
                headers: {
                  Authorization: `${jwt}`,
                  "Content-Type": "application/json",
                },
              }
            );

            // console.log("res-->", res);
            if (res.data.data === "SUCCESSFUL") {
              toast.success("Amount successfully converted to Rags Fund!");
              GetUserDetails();
              setAmount(0);
              setToken(0);
              setspiner(false);
            } else {
              toast.error(res.data.data);
              setspiner(false);
            }

            setspiner(false);
          }
        } else {
          toast.error("Invalid Wallet address");
        }
      }
    } catch (err) {
      // console.log("error while calling fuction sell", err);
      if (
        err.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
      setspiner(false);
    }
  };

  useEffect(() => {
    WalletConnected();
    getPriceForLiverate();
    GetUserDetails();

    setInterval(() => {
      getPriceForLiverate();
    }, 5000);
  }, []);

  // togglar
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };
  return (
    <div>
      <div className="container-fluid NewDashoard px-0">
        <div className="row">
          <div className="col-auto p-0">
            <div
              className={`sidebar bg-dark text-white ${
                isSidebarCollapsed ? "collapsed" : ""
              }`}
            >
              <Sidebar />
            </div>
          </div>

          {/* Main Content */}
          <div className="col">
            <div className="flex-grow-1 marginl">
              {/* Header */}
              <header className="header text-white p-3 d-flex justify-content-end align-items-center">
                {/* <div className="fw-bold fs-4 headingdah">METARAGS</div> */}
                <div className="Groupitems">
                  <div className="helpbtn">
                    <i class="fa fa-folder-open" aria-hidden="true"></i>
                  </div>
                  <a href="/Helpdesk" className="mailbtn">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                  </a>
                  <div className="dropdown">
                    <a
                      className="btn btnsecondary dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="iconimage">
                        <img
                          className="w-100 h-10"
                          src="/assets/image/logo/logo.svg"
                          alt=""
                        />
                      </div>
                      User id:-
                      <span>{user}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="btn btn-light togglebtn"
                  onClick={toggleSidebar}
                >
                  ☰
                </button>
              </header>

              {/* Content Area */}
              <div
                className={`content-area ${
                  isSidebarCollapsed ? "collapsed" : ""
                }`}
              >
                <div className="row g-3 mx-0 h-100">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="fs-18 text-white fw-bold">Withdrawal</div>
                      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Withdrawal</div>
                        <div className="ps-3">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                              <li className="breadcrumb-item">
                                <a href="">
                                  <i className="bx bx-home-alt text-white" />
                                </a>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                              >
                                Convert to Rags Fund
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8 mx-auto">
                    <div className="modal-dialog">
                      <div className="modal-content exchange_width">
                        <div className="modal-header exchange_hd w-100 flex-wrap ">
                          <h4 className="w-100 text-center pt-2">
                            Wallet is Connected
                          </h4>
                          <p className="text-white fs-16 text-center w-100">
                            Available Net Balance : $ {USDT}
                          </p>
                          <p className="text-white fs-16 text-center w-100">
                            Live Rate : {LiveRate}
                          </p>
                        </div>
                        <div className="modal-body">
                          <form className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0">
                            <div className="panel-body">
                              <div className="row align-items-center justify-content-center">
                                <div className="col-md-4 col-6">
                                  <label>Wallet Address </label>
                                </div>
                                <div className="col-md-8 col-6">
                                  <input
                                    type="text"
                                    className="form-control mb-20  shadow-none"
                                    readOnly
                                    value={Wallet_Address}
                                    style={{ width: "90%" }}
                                  />
                                </div>
                              </div>

                              <div className="row align-items-center justify-content-center">
                                <div className="col-md-5 col-6">
                                  <label>Enter USDT </label>
                                </div>
                                <div className="col-md-7 col-6">
                                  <input
                                    type="text"
                                    className="form-control mb-20  shadow-none"
                                    id="amountInput"
                                    min={1}
                                    step={1}
                                    maxLength={4}
                                    value={Amount}
                                    onChange={(e) =>
                                      GetWithdrawalAmountData(e.target.value)
                                    }
                                    style={{ width: "90%" }}
                                  />
                                </div>
                              </div>

                              {/* <div className="row align-items-center justify-content-center">
                                <div className="col-md-5 col-6">
                                  <label>Fees & Ranking Rewards </label>
                                </div>
                                <div className="col-md-7 col-6">
                                  <input
                                    type="text"
                                    className="form-control mb-20  shadow-none"
                                    id="amountInput1"
                                    value={Fees}
                                    readOnly
                                    style={{ width: "90%" }}
                                  />
                                </div>
                              </div> */}

                              {/* <div className="row align-items-center justify-content-center">
                                <div className="col-md-5 col-6">
                                  <label>Net Payable </label>
                                </div>
                                <div className="col-md-7 col-6">
                                  <input
                                    type="text"
                                    className="form-control mb-20  shadow-none"
                                    id="amountInput1"
                                    value={NetPayable}
                                    readOnly
                                    style={{ width: "90%" }}
                                  />
                                </div>
                              </div> */}
                              <div className="row align-items-center justify-content-center">
                                <div className="col-md-5 col-6">
                                  <label>Rags Topup Amount </label>
                                </div>
                                <div className="col-md-7 col-6">
                                  <input
                                    type="text"
                                    className="form-control mb-20  shadow-none"
                                    id="amountInput1"
                                    value={`${gameFees} (${parseFloat(
                                      parseFloat(gameFees) *
                                        parseFloat(gameFeeslive)
                                    ).toFixed(2)})`}
                                    readOnly
                                    style={{ width: "90%" }}
                                  />
                                </div>
                              </div>
                              {/* <div className="row align-items-center justify-content-center">
                                <div className="col-md-5 col-6">
                                  <label>Net Payable(USDT) </label>
                                </div>
                                <div className="col-md-7 col-6">
                                  <input
                                    type="text"
                                    className="form-control mb-20  shadow-none"
                                    id="amountInput1"
                                    value={NetPayableUSDT}
                                    readOnly
                                    style={{ width: "90%" }}
                                  />
                                </div>
                              </div> */}
                              {/* <div className="row align-items-center justify-content-center">
                                <div className="col-md-5 col-6">
                                  <label>Net Payable(RagCoin) </label>
                                </div>
                                <div className="col-md-7 col-6">
                                  <input
                                    type="text"
                                    className="form-control mb-20  shadow-none"
                                    id="amountInput1"
                                    value={
                                      NetPayableToken
                                    }
                                    readOnly
                                    style={{ width: "90%" }}
                                  />
                                </div>
                              </div> */}
                              <div className="row align-items-center justify-content-center mx-0 pt-3">
                                <div className="col-6 row align-items-center justify-content-center">
                                  {/* {parseInt(TopupStatus) === 0 && ( */}
                                  <button
                                    className="btn btn-theme1 withdraw_btn w-100 py-3 text-black fw-bold shadow-none"
                                    type="button"
                                    onClick={() => {
                                      ConvertToRagFundUser();
                                    }}
                                  >
                                    Convert Fund
                                  </button>
                                  {/* )} */}
                                </div>
                                <div className="col-12 ">
                                  <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                                    Note: You can not convert back Rags Fund to
                                    Withdrawal Fund.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConvertToRagFund;
