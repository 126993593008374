import React, { useEffect, useState } from "react";
import "./DashboardMain.css";
import DashHeader from "../DashHeader/DashHeader";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../Utils/API";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utils/contract";
import { updateAuth, updateStatus } from "../../Redux/AuthSlice";
import Timer from "../Timer/Timer";
import { IoIosLock } from "react-icons/io";
import Sidebar from "./Sidebar";

const DashboardMain = () => {
  const user = useSelector((state) => state.Auth.userId);
  const sec = useSelector((state) => state.Auth.jwtToken);
  const ip = useSelector((state) => state.Auth.ipAddress);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let URL = window.location.origin;
  const LeftCopyAddressHandler = () => {
    const text = `${URL}/Register?userid=${user}&position=Left`;
    //  `${URL}/registration?userid=${user}`
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const RightCopyAddressHandler = () => {
    const text = `${URL}/Register?userid=${user}&position=Right`;

    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const [dash, setdataArray] = useState([]);
  const [rankList, setRankList] = useState([]);

  const [walletAddress, setwalletAddress] = useState("ConnectWallet");

  const [currenttimer, setcurrenttimer] = useState(0);
  const [Days_here_booster, setDays_here_booster] = useState(0);
  const [Hours_here_booster, setHours_here_booster] = useState(0);
  const [Munits_here_booster, setMunits_here_booster] = useState(0);
  const [Seconds_booster, setSeconds_booster] = useState(0);

  const Dashboard_API = async () => {
    try {
      // let acc = await loadWeb3();
      // setwalletAddress(acc);
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${sec}`,
          "Content-Type": "application/json",
        },
      });
      //console.log("responce--->", responce.data.data[0][0]);
      responce = responce.data.data[0][0];
      setcurrenttimer(responce?.Flushtimer_status);
      setwalletAddress(responce?.btcaddress);
      //console.log("responcess--->", responce);
      setdataArray(responce);
      if (responce.CurrentPackage > 0) {
        dispatch(updateStatus({ status: "Active" }));
      } else {
        dispatch(updateStatus({ status: "In-Active" }));
      }
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e?.response?.data?.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const Rank_API = async () => {
    try {
      let responce = await API?.get(`downlineRankList?uid=${user}`, {
        headers: {
          Authorization: `${sec}`,
          "Content-Type": "application/json",
        },
      });
      //console.log("responceRank--->", responce?.data?.data?.records);
      responce = responce?.data?.data?.records;
      setRankList(responce);
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e?.response?.data?.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const AddressCopyAddressHandler = () => {
    const text = walletAddress;

    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const HandleRankDetails = (rank) => {
    window.open(`/RankDownline/${rank}`, "_blank");
  };

  const Timer2 = currenttimer;
  const timer2 = async () => {
    try {
      var currentDateTime = new Date();
      //console.log("currentDateTime", currentDateTime);
      let resultInSeconds = currentDateTime.getTime() / 1000;
      let topupInSeconds = new Date(Timer2).getTime() / 1000;
      let Time_here = topupInSeconds - resultInSeconds;
      let TimeFinal = parseInt(Time_here);
      if (TimeFinal <= 0 || Timer2 === "") {
        setDays_here_booster(0);
        setHours_here_booster(0);
        setMunits_here_booster(0);
        setSeconds_booster(0);
      } else {
        let days = parseInt(TimeFinal / 86400);
        setDays_here_booster(days);
        TimeFinal = TimeFinal % 86400;
        let hours = parseInt(TimeFinal / 3600);
        setHours_here_booster(hours);
        TimeFinal %= 3600;
        let munites = parseInt(TimeFinal / 60);
        setMunits_here_booster(munites);
        TimeFinal %= 60;
        let second_here = parseInt(TimeFinal);
        setSeconds_booster(second_here);
      }
    } catch (e) {
      console.log("Error While Timer", e);
    }
  };

  useEffect(() => {
    Dashboard_API();
    Rank_API();
    if (currenttimer) {
      setInterval(() => {
        timer2();
      }, 1000);
    }
  }, [currenttimer]);

  // togglar
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };
  // percentage

  const [percentage, setPercentage] = useState(55); // Default percentage

  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);

    // Ensure the percentage is between 0 and 100
    if (value >= 0 && value <= 100) {
      setPercentage(value);
    }
  };

  // Calculate segment distribution
  const redSegment = 100 - parseInt(dash?.EarnPer); // Math.min(percentage, 20); // Blue covers up to 20%
  const yellowSegment = 0; //Math.min(Math.max(percentage - 20, 0), 35); // Yellow covers the next 35%
  const greenSegment = parseInt(dash?.EarnPer); //Math.max(100 - (redSegment + yellowSegment), 0); // Green fills the rest
  const colorCheck = parseInt(dash?.EarnPer) >= 90 ? "Red" : "#198754"; //Math.max(100 - (redSegment + yellowSegment), 0); // Green fills the rest

  const backgroundStyle = {
    background: `conic-gradient(
     black 0% ${redSegment}%,
     #ffc107 ${redSegment}% ${redSegment + yellowSegment}%, 
     ${colorCheck} ${redSegment + yellowSegment}% 100%
   )`,
  };

  return (
    <div>
      <div className="container-fluid NewDashoard px-0">
        <div className="row">
          <div className="col-auto p-0">
            <div
              className={`sidebar bg-dark text-white ${
                isSidebarCollapsed ? "collapsed" : ""
              }`}
            >
              <Sidebar />
            </div>
          </div>

          {/* Main Content */}
          <div className="col">
            <div className="flex-grow-1 marginl">
              {/* Header */}
              <header className="header text-white p-3 d-flex justify-content-end align-items-center">
                {/* <div className="fw-bold fs-4 headingdah">METARAGS</div> */}
                <div className="Groupitems">
                  <div className="helpbtn">
                    <i className="fa fa-folder-open" aria-hidden="true"></i>
                  </div>
                  <a href="/Helpdesk" className="mailbtn">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </a>
                  <div className="dropdown">
                    <a
                      className="btn btnsecondary dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="iconimage">
                        <img
                          className="w-100 h-10"
                          src="/assets/image/logo/logo.svg"
                          alt=""
                        />
                      </div>
                      User id:- <span>{user}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="btn btn-light togglebtn"
                  onClick={toggleSidebar}
                >
                  ☰
                </button>
              </header>

              {/* Content Area */}
              <div
                className={`content-area ${
                  isSidebarCollapsed ? "collapsed" : ""
                }`}
              >
                <div className="row mx-0 g-3 h-100">
                  <div className="col-lg-8 pe-1">
                    <div className="row mx-0 g-3">
                      <div className="col-xl-6">
                        <div className="card card-top">
                          <div className="card-heading">
                            <div className="headingcard">My Package</div>
                            <div>
                              <div className="iconimage">
                                <img
                                  src={`https://ragsmeta.community/assets/image/star/${dash.RankIconname}`}
                                  alt="rank"
                                  className="w-100 h-100"
                                />
                                <br />
                              </div>
                            </div>
                          </div>
                          <div className="premium">
                            (
                            <span style={{ color: "#f9b615" }}>
                              {dash.Rankname}
                            </span>
                            )
                          </div>
                          <div className="payment"> $ {dash?.TotalInvest}</div>
                          {/* <div className="heads">Your withdrawal limit is {dash.WithLimit} USDT</div> */}
                          <div className="bottomcardcontent">
                            <div className="leftside">
                              <div className="leftamoun">
                                <span className="arrowicon">
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>{" "}
                                Current
                              </div>
                              <div className="leftpayment">
                                $ {dash?.CurrentPackage}
                              </div>
                            </div>
                            <div className="rightside">
                              <div className="rightamoun">
                                <span className="arrowicon">
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>{" "}
                                Your Daily Withdrawal
                              </div>
                              <div className="rightpayment">
                                Limit is{" "}
                                <span style={{ color: "#f9b615" }}>
                                  {dash.WithLimit} USDT
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="card card-top-left">
                          <div className="card-heading">Income Limit</div>
                          <div className="leftrightcontentcard">
                            <div className="leftsidecontent">
                              <div className="circular-graph">
                                <div className="circle" style={backgroundStyle}>
                                  <div className="center-content">
                                    <span id="percentage">{dash.EarnPer}%</span>
                                    <input
                                      type="text"
                                      id="percentage-input"
                                      placeholder="Enter percentage"
                                      min="0"
                                      max="100"
                                      value={percentage}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="rightsidecontent">
                              <ul className="listitems">
                                <li>
                                  <div className="icon bg-warning">
                                    <img
                                      src="/assets/image/Dashboardicon/Total-Income.png"
                                      alt=""
                                      className="w-100 h-100"
                                    />
                                  </div>
                                  <div className="contents">
                                    <div className="heading">
                                      Total Investment
                                    </div>
                                    <div className="payment">
                                      $ {dash.TotalInvest}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="icon bg-success">
                                    <img
                                      src="/assets/image/Dashboardicon/Net-Balance.png"
                                      alt=""
                                      className="w-100 h-100"
                                    />
                                  </div>
                                  <div className="contents">
                                    <div className="heading">Total Limit</div>
                                    <div className="payment">
                                      $ {dash.MaxIncome}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="icon bg-danger">
                                    <img
                                      src="/assets/image/Dashboardicon/Total-Withdrawal.png"
                                      alt=""
                                      className="w-100 h-100"
                                    />
                                  </div>
                                  <div className="contents">
                                    <div className="heading">Total Earn</div>
                                    <div className="payment">
                                      $ {dash.EarnAmount}
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="headingmain">All Income</div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-info">
                              <i className="fa fa-money" aria-hidden="true"></i>
                            </span>{" "}
                            Direct Income
                          </div>
                          <div className="amount">$ {dash?.Direct_Income}</div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-warning">
                              <img
                                src="/assets/image/Dashboardicon/ROI-Income.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>{" "}
                            ROI Income
                          </div>
                          <div className="amount">$ {dash?.Roi_Income}</div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-primary">
                              <img
                                src="/assets/image/Dashboardicon/ROI-Level-Income.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>{" "}
                            ROI Level Income
                          </div>
                          <div className="amount">
                            $ {dash?.Roi_Level_Income}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-success">
                              <img
                                src="/assets/image/Dashboardicon/Total-Matching-Income.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>{" "}
                            Total Matching Income
                          </div>
                          <div className="amount">$ {dash?.TotalBinary}</div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-info">
                              <img
                                src="/assets/image/Dashboardicon/Paid-Matching-Income.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>{" "}
                            Paid Matching Income
                          </div>
                          <div className="amount">$ {dash?.PaidBinary}</div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-warning">
                              <img
                                src="/assets/image/Dashboardicon/Remain-Matching-Income.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>{" "}
                            Remain Matching Income
                          </div>
                          <div className="amount">$ {dash?.RemainBinary}</div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-primary">
                              <img
                                src="/assets/image/Dashboardicon/Total-Income.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>
                            Total Income
                          </div>
                          <div className="amount">$ {dash.Total_Income}</div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-success">
                              <img
                                src="/assets/image/Dashboardicon/Total-Withdrawal.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>
                            Total Withdrawal
                          </div>
                          <div className="amount">
                            $ {dash.WithDrawal_Amount}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-info">
                              <img
                                src="/assets/image/Dashboardicon/Net-Balance.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>
                            Net Balance
                          </div>
                          <div className="amount">$ {dash.Net_Balance}</div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-warning">
                              <img
                                src="/assets/image/Dashboardicon/Today-Income.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>
                            Today Income
                          </div>
                          <div className="amount"> $ {dash.Today_Income}</div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small Ankerbtn">
                          <div className="card-heading">
                            <span className="icon bg-primary">
                              <i
                                className="fa fa-share-alt"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <button
                              type="button"
                              className="btn "
                              onClick={LeftCopyAddressHandler}
                            >
                              {" "}
                              Copy Left Referral <br></br> Link
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_Small Ankerbtn">
                          <div className="card-heading">
                            <span className="icon bg-primary">
                              {" "}
                              <i
                                className="fa fa-share-alt"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <button
                              type="button"
                              className="btn"
                              onClick={RightCopyAddressHandler}
                            >
                              Copy Right Referral Link
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card Bottomcard">
                          <div className="card-header">
                            <div className="card-title">Ranking</div>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive tbhight">
                              <table>
                                <thead>
                                  <tr className="text-uppercase bg-lightest">
                                    <th>
                                      <span className="text-th">Rank</span>
                                    </th>
                                    <th>
                                      <span className="text-th">Total Id</span>
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rankList?.map((value, index) => {
                                    return (
                                      <tr key={value.rank}>
                                        <td>
                                          <div className="d-flex align-items-center gap-3">
                                            <div className="imagerankicon">
                                              {" "}
                                              <img
                                                className="w-100 h-100"
                                                src={`https://ragsmeta.community/assets/image/star/${value.rank_img}`}
                                                alt=""
                                              />
                                            </div>
                                            <div className="rankname">
                                              {value.rank_name}
                                            </div>
                                          </div>
                                        </td>
                                        <td>{value.totalid}</td>
                                        <td>
                                          <button
                                            onClick={(e) =>
                                              HandleRankDetails(value.rank)
                                            }
                                            className="btn btn-theme1 text-black"
                                          >
                                            View
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-12">
                        <div className="card">
                          <div className="card-image metdardgig">
                            <img
                              className="w-100 h-100"
                              src="/assets/image/giphy.gif"
                              alt="Graph"
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-4 px-1">
                    <div className="row g-3 mx-0">
                      <div className="col-12 px-1">
                        <div className="card Rightsidecardtop">
                          <div className="card-Heading">Team Size</div>
                          <ul className="listright">
                            <li>
                              <div className="icon bg-danger">
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Left-Total </div>
                              <div className="arrowicondown">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                {dash?.TotalLeftTeam}
                              </div>
                            </li>
                            <li>
                              <div className="icon bg-primary">
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Left-Active</div>
                              <div className="arrowicontop">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                {dash?.TotalLeftActiveTeam}
                              </div>
                            </li>
                            <li>
                              <div className="icon bg-danger">
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Right-Total</div>
                              <div className="arrowicondown">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                {dash?.TotalRightTeam}
                              </div>
                            </li>
                            <li>
                              <div className="icon bg-primary">
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Right Active</div>
                              <div className="arrowicontop">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                {dash?.TotalRightActiveTeam}
                              </div>
                            </li>
                          </ul>
                          <div className="card-Heading">My Referral</div>
                          <ul className="listright">
                            <li>
                              <div className="icon bg-danger">
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Left-Total </div>
                              <div className="arrowicondown">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                {dash?.TotalLeftReferral}
                              </div>
                            </li>
                            <li>
                              <div className="icon bg-primary">
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Left-Active</div>
                              <div className="arrowicontop">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                {dash?.TotalLeftActiveReferral}
                              </div>
                            </li>
                            <li>
                              <div className="icon bg-danger">
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Right-Total</div>
                              <div className="arrowicondown">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                {dash?.TotalRightReferral}
                              </div>
                            </li>
                            <li>
                              <div className="icon bg-primary">
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Right-Active</div>
                              <div className="arrowicontop">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                {dash?.TotalRightActiveReferral}
                              </div>
                            </li>
                          </ul>
                          <div className="card-Heading">Team Business</div>
                          <ul className="listright">
                            <li>
                              <div className="icon bg-danger">
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Left-Total </div>
                              <div className="arrowicondown">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                {" "}
                                $ {dash.totalLeftTeamBusiness}
                              </div>
                            </li>
                            <li>
                              <div className="icon bg-primary">
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Left-Today</div>
                              <div className="arrowicontop">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                ${dash?.todayLeftTeamBusiness}
                              </div>
                            </li>
                            <li>
                              <div className="icon bg-danger">
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Right-Total</div>
                              <div className="arrowicondown">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                $ {dash.totalRightTeamBusiness}
                              </div>
                            </li>
                            <li>
                              <div className="icon bg-primary">
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="heading">Right-Today</div>
                              <div className="arrowicontop">
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="amount">
                                {" "}
                                $ {dash?.todayRightTeamBusiness}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-12 col-12 col-sm-6 col-md-6 px-1">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-warning">
                              <img
                                src="/assets/image/Dashboardicon/Net-Game-Amount.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>
                            Open Level
                          </div>
                          <div className="amount">{dash.openLevel}</div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-12 col-sm-6 col-md-6 px-1">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-success">
                              <img
                                src="/assets/image/Dashboardicon/Total-Game-Amount.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>{" "}
                            Total Activation Fund
                          </div>
                          <div className="amount">
                            {dash.TotalGameAmount} Rags
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-12 col-12 col-sm-6 col-md-6 px-1">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-warning">
                              <img
                                src="/assets/image/Dashboardicon/Transfer-To-Game-Wallet.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>{" "}
                            Transfer To Game Wallet
                          </div>
                          <div className="amount">{dash.GameWallet} Rags</div>
                        </div>
                      </div> */}
                      <div className="col-lg-12 col-12 col-sm-6 col-md-6 px-1">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-info">
                              <img
                                src="/assets/image/Dashboardicon/Received-Game-Amount.png"
                                alt="icon"
                                className="w-100 h-100"
                              />
                            </span>{" "}
                            Received Activation Fund
                          </div>
                          <div className="amount">
                            {dash.ReceivedGameWallet} Rags
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-12 col-sm-6 col-md-6 px-1">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-success">
                              <img
                                className="w-100 h-100"
                                src="/assets/image/Dashboardicon/Game-Amount-Transfer-History.png"
                                alt="icon"
                              />
                            </span>
                            Activation Fund Transfer History
                          </div>
                          <div className="amount">
                            {dash.TransferedGameWallet} Rags
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-12 col-sm-6 col-md-6 px-1">
                        <div className="card card_Small">
                          <div className="card-heading">
                            <span className="icon bg-warning">
                              <img
                                src="/assets/image/Dashboardicon/Net-Game-Amount.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>
                            Net Activation Fund
                          </div>
                          <div className="amount">
                            {dash.NetGameAmount} Rags
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-12 col-sm-6 col-md-6 px-1">
                        <Link
                          to="/GameAmountTransfer"
                          className="card card_Small text-decration-none Ankerbtn"
                        >
                          <div className="card-heading">
                            <span className="icon bg-info">
                              <img
                                src="/assets/image/Dashboardicon/P2P-Transfer.png"
                                alt=""
                                className="w-100 h-100"
                              />
                            </span>{" "}
                            Activation P2P Transfer
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardMain;
